@import url("./assets/lib/hljs/styles/atom-one-light.css");
@import url("../node_modules/ng-zorro-antd/src/ng-zorro-antd.min.css");
@import url("../node_modules/@fortawesome/fontawesome-free/css/all.min.css");
@import "./assets/scss/app.scss";
@import '~ng-zorro-antd/ng-zorro-antd.min.css';

.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
  padding-right: 20px;
}

.ant-input {
  padding: 0.4rem 1rem;
  line-height: 1.5;
  color: #53535f;
  height: auto;
  border: 1px solid #edf2f9;
  border-radius: 0.25rem;
}
